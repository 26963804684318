import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingIndicatorService } from '../../../../../services/loading-indicator/loading-indicator.service';
import { EventsService } from '../../../../../services/events/events.service';
import { environment } from '../../../../../../environments/environment';
import { CountryCodeService } from '../../../../../services/country-code/country-code.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { FieldsBasicPlaceDetails } from '../../../../../shared/consts/fields-basic-place-details';

@Component({
  selector: 'tripmakery-destination-info-destination-edit',
  templateUrl: './destination-info-destination-edit.component.html',
})
export class DestinationInfoDestinationEditComponent implements OnInit {
  public destinationGooglePlaceDetails;

  public bookingChangeRequestId: string;
  public form: FormGroup;
  public environment_;
  public isLoading: boolean = false;
  public options = {
    fields: FieldsBasicPlaceDetails,
  };

  public isEmailInUse: boolean;
  public emailInUseUserId: string;
  public emailInUseUrl: string;

  @Input() destination;

  public googleDataDe: any;
  public googleDataEn: any;
  public googleDataEnGb: any;
  public googleDataEnAu: any;
  public googleDataEs: any;
  public googleDataIt: any;
  public googleDataPl: any;

  public googlePlaceId: string;

  public countryListEnglish: Array<{ countryCode: string, name: string }>;

  constructor(private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService,
              private router: Router,
              public countryCodeService: CountryCodeService,
  ) {
    this.countryListEnglish = this.countryCodeService.getAllEnglishCountryDataAsArray();

    this.form = new FormGroup({
      'googleAddressText': new FormControl({value: '', disabled: false}),
      'destinationNameDe': new FormControl({value: '', disabled: false}),
      'destinationNameEnGb': new FormControl({value: '', disabled: false}),
      'destinationNameEn': new FormControl({value: '', disabled: false}),
      'destinationNameEs': new FormControl({value: '', disabled: false}),
      'destinationNameIt': new FormControl({value: '', disabled: false}),
      'destinationNameEnAu': new FormControl({value: '', disabled: false}),
      'destinationNamePl': new FormControl({value: '', disabled: false}),
      'slugsDestinationDe': new FormControl({value: '', disabled: true}),
      'slugsDestinationEnGb': new FormControl({value: '', disabled: true}),
      'slugsDestinationEn': new FormControl({value: '', disabled: true}),
      'slugsDestinationEs': new FormControl({value: '', disabled: true}),
      'slugsDestinationIt': new FormControl({value: '', disabled: true}),
      'slugsDestinationEnAu': new FormControl({value: '', disabled: true}),
      'slugsDestinationPl': new FormControl({value: '', disabled: true}),
      'countryCode': new FormControl({value: '', disabled: false}),
      'priceFrom': new FormControl({value: '', disabled: false}),
      'prio': new FormControl({value: '', disabled: false}),
      'isCountry': new FormControl({value: false, disabled: false}),
      'isCapital': new FormControl({value: false, disabled: false}),
      'isFederalState': new FormControl({value: false, disabled: false}),
      'isEnabled': new FormControl({value: false, disabled: false}),
      'hasHeaderImage': new FormControl({value: false, disabled: false}),
      'isListDestination': new FormControl({value: false, disabled: false}),
    });
    this.environment_ = environment;
  }

  public ngOnInit() {
    this.form = new FormGroup({
      'googleAddressText': new FormControl({value: this.destination.destinationName.de, disabled: false}),
      'destinationNameDe': new FormControl({value: this.destination.destinationName.de, disabled: false}),
      'destinationNameEnGb': new FormControl({value: this.destination.destinationName['en-gb'], disabled: false}),
      'destinationNameEn': new FormControl({value: this.destination.destinationName.en, disabled: false}),
      'destinationNameEs': new FormControl({value: this.destination.destinationName.es, disabled: false}),
      'destinationNameIt': new FormControl({value: this.destination.destinationName.it, disabled: false}),
      'destinationNameEnAu': new FormControl({value: this.destination.destinationName['en-au'], disabled: false}),
      'destinationNamePl': new FormControl({value: this.destination.destinationName.pl, disabled: false}),
      'slugsDestinationDe': new FormControl({value: this.destination.slugsDestination.de, disabled: true}),
      'slugsDestinationEnGb': new FormControl({value: this.destination.slugsDestination['en-gb'], disabled: true}),
      'slugsDestinationEn': new FormControl({value: this.destination.slugsDestination.en, disabled: true}),
      'slugsDestinationEs': new FormControl({value: this.destination.slugsDestination.es, disabled: true}),
      'slugsDestinationIt': new FormControl({value: this.destination.slugsDestination.it, disabled: true}),
      'slugsDestinationEnAu': new FormControl({value: this.destination.slugsDestination['en-au'], disabled: true}),
      'slugsDestinationPl': new FormControl({value: this.destination.slugsDestination.pl, disabled: true}),
      'countryCode': new FormControl({value: this.destination.countryCode, disabled: false}),
      'priceFrom': new FormControl({value: this.destination.priceFrom, disabled: false}),
      'prio': new FormControl({value: this.destination.prio, disabled: false}),
      'isCountry': new FormControl({value: this.destination.isCountry === true, disabled: false}),
      'isCapital': new FormControl({value: this.destination.isCapital === true, disabled: false}),
      'isFederalState': new FormControl({value: this.destination.isFederalState === true, disabled: false}),
      'isEnabled': new FormControl({value: this.destination.isEnabled === true, disabled: false}),
      'hasHeaderImage': new FormControl({value: this.destination.hasHeaderImage === true, disabled: false}),
      'isListDestination': new FormControl({value: this.destination.isListDestination === true, disabled: false}),
    });

    this.googlePlaceId = this.destination.googlePlaceId;
  }


  public handleClick() {
    this.showLoading();
    const body = this.createCreationBody();
    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/pages/destinations/' + this.destination._id, body)
      .subscribe(
        (response: any) => {
          this.eventsService.broadcast('destinationInfoReloadDestinationData');
        },
        (err) => {
          if (err.error.message === 'E_VALIDATION_FAILED') {
            console.log('Just a validation Error');
            try {
              for (const error of err.error.errors) {
                this.form.get(error.property).setErrors(error.constraints);
                this.form.get(error.property).markAsDirty();

                console.log(error.property, error.constraints);
              }
            } catch (error) {
              console.log('Catched E', error);
            }
            this.hideLoading();
            return;
          } else if (err.error.message === 'E_EMAIL_ALREADY_IN_USE') {
            this.isEmailInUse = true;
            this.emailInUseUserId = err.error.userId;
            this.emailInUseUrl = environment.urls.tool + '/users/' + err.error.userId;
            this.hideLoading();
            return;
          }

          console.log(err);

          this.hideLoading();
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(err));
        },
        () => {
          this.hideLoading();
        }
      );
  }

  public extractValidationErrorsAsArray(errors) {
    if (errors == null) {
      return [];
    }

    const errorsToReturn = [];
    Object.keys(errors).forEach(key => {
      errorsToReturn.push(errors[key]);
    });

    return errorsToReturn;
  }

  public showLoading() {
    this.isLoading = true;
    this.loadingIndicatorService.showLoadingBar();
  }

  public hideLoading() {
    this.isLoading = false;
    this.loadingIndicatorService.hideLoadingBar();
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return null;
    }
    return s;
  }

  private createFalseIfNull(element) {
    if (element == null) {
      return false;
    }
    return element;
  }

  private createCreationBody() {
    /* */
    const countryCode = this.createNullIfEmptyString(this.form.get('countryCode').value);
    const countryNameDe = countryCode != null ? this.countryCodeService.countryListAlpha2German[countryCode] : null;
    const countryNameEn = countryCode != null ? this.countryCodeService.countryListAlpha2English[countryCode] : null;
    const countryNameEnGb = countryCode != null ? this.countryCodeService.countryListAlpha2English[countryCode] : null;
    const countryNameEs = countryCode != null ? this.countryCodeService.countryListAlpha2Spanish[countryCode] : null;
    const countryNameIt = countryCode != null ? this.countryCodeService.countryListAlpha2Italian[countryCode] : null;
    const countryNameEnAu = countryCode != null ? this.countryCodeService.countryListAlpha2English[countryCode] : null;
    const countryNamePl = countryCode != null ? this.countryCodeService.countryListAlpha2Polish[countryCode] : null;

    /* */
    const slugsCountryNameDe = countryCode != null ? this.createSlugFromString(countryNameDe) : null;
    const slugsCountryNameEn = countryCode != null ? this.createSlugFromString(countryNameEn) : null;
    const slugsCountryNameEnGb = countryCode != null ? this.createSlugFromString(countryNameEnGb) : null;
    const slugsCountryNameEs = countryCode != null ? this.createSlugFromString(countryNameEs) : null;
    const slugsCountryNameIt = countryCode != null ? this.createSlugFromString(countryNameIt) : null;
    const slugsCountryNameEnAu = countryCode != null ? this.createSlugFromString(countryNameEnAu) : null;
    const slugsCountryNamePl = countryCode != null ? this.createSlugFromString(countryNamePl) : null;

    return {
      /* */
      googlePlaceId: this.googleDataDe != null ? this.googleDataDe.place_id : this.googlePlaceId,
      destinationNameDe: this.createNullIfEmptyString(this.form.get('destinationNameDe').value),
      destinationNameEn: this.createNullIfEmptyString(this.form.get('destinationNameEn').value),
      destinationNameEnGb: this.createNullIfEmptyString(this.form.get('destinationNameEnGb').value),
      destinationNameEs: this.createNullIfEmptyString(this.form.get('destinationNameEs').value),
      destinationNameIt: this.createNullIfEmptyString(this.form.get('destinationNameIt').value),
      destinationNameEnAu: this.createNullIfEmptyString(this.form.get('destinationNameEnAu').value),
      destinationNamePl: this.createNullIfEmptyString(this.form.get('destinationNamePl').value),

      slugsDestinationDe: this.createNullIfEmptyString(this.form.get('slugsDestinationDe').value),
      slugsDestinationEn: this.createNullIfEmptyString(this.form.get('slugsDestinationEn').value),
      slugsDestinationEnGb: this.createNullIfEmptyString(this.form.get('slugsDestinationEnGb').value),
      slugsDestinationEs: this.createNullIfEmptyString(this.form.get('slugsDestinationEs').value),
      slugsDestinationIt: this.createNullIfEmptyString(this.form.get('slugsDestinationIt').value),
      slugsDestinationEnAu: this.createNullIfEmptyString(this.form.get('slugsDestinationEnAu').value),
      slugsDestinationPl: this.createNullIfEmptyString(this.form.get('slugsDestinationPl').value),

      priceFrom: this.tryToCreateNumberOrReturnNullIfFailed(this.form.get('priceFrom').value),
      prio: this.tryToCreateNumberOrReturnNullIfFailed(this.form.get('prio').value),

      /* */
      isCountry: this.createFalseIfNull(this.form.get('isCountry').value),
      isCapital: this.createFalseIfNull(this.form.get('isCapital').value),
      isFederalState: this.createFalseIfNull(this.form.get('isFederalState').value),
      isListDestination: this.createFalseIfNull(this.form.get('isListDestination').value),
      isEnabled: this.createFalseIfNull(this.form.get('isEnabled').value),
      hasHeaderImage: this.createFalseIfNull(this.form.get('hasHeaderImage').value),

      /* */
      countryCode,
      countryNameDe,
      countryNameEn,
      countryNameEnGb,
      countryNameEs,
      countryNameIt,
      countryNameEnAu,
      countryNamePl,

      slugsCountryNameDe,
      slugsCountryNameEn,
      slugsCountryNameEnGb,
      slugsCountryNameEs,
      slugsCountryNameIt,
      slugsCountryNameEnAu,
      slugsCountryNamePl,

      /* */
      googleDataDe: this.googleDataDe,
      googleDataEn: this.googleDataEn,
      googleDataEnGb: this.googleDataEnGb,
      googleDataEs: this.googleDataEs,
      googleDataIt: this.googleDataIt,
      googleDataEnAu: this.googleDataEnAu,
      googleDataPl: this.googleDataPl,

      /* */
      languageVersionEnabledDe: false,
      languageVersionEnabledEn: false,
      languageVersionEnabledEnGb: false,
      languageVersionEnabledEs: false,
      languageVersionEnabledIt: false,
      languageVersionEnabledEnAu: false,
      languageVersionEnabledPl: false,

      /* */
      recommendedGroups: null,
    };
  }

  public async getGooglePlaceDetailsFromService() {
    const response = await this.http.get(environment.urls.service + '/api/v1/authenticated/admin/helper/google/google-place-id/' + this.destinationGooglePlaceDetails.place_id + '?languages[]=en&languages[]=de&languages[]=es&languages[]=it&languages[]=pl', {}).toPromise();

    const elements = (response as any).googleElements;
    for (const element of elements) {
      if (element.language === 'en') {
        this.googleDataEn = element.googleResponse.result;
        this.googleDataEnGb = element.googleResponse.result;
        this.googleDataEnAu = element.googleResponse.result;
      }
      if (element.language === 'de') {
        this.googleDataDe = element.googleResponse.result;
      }
      if (element.language === 'es') {
        this.googleDataEs = element.googleResponse.result;
      }
      if (element.language === 'it') {
        this.googleDataIt = element.googleResponse.result;
      }
      if (element.language === 'pl') {
        this.googleDataPl = element.googleResponse.result;
      }
    }

    this.mapGoogleDetailsToForm();
  }

  private tryToDetermineCountry() {
    for (const addressComponent of this.googleDataDe.address_components) {
      if (addressComponent.types.indexOf('country') !== -1) {
        return addressComponent.short_name;
      }
    }

    return null;
  }

  private tryToCreateNumberOrReturnNullIfFailed(s: string) {
    const num = parseFloat(s);
    if (isNaN(num)) {
      return null;
    }
    return num;
  }

  private mapGoogleDetailsToForm() {
    /* */
    const countryCode = this.tryToDetermineCountry();
    if (countryCode != null) {
      this.form.patchValue({'countryCode': countryCode});
    }

    /* */
    this.form.patchValue({'destinationNameDe': this.googleDataDe.name});
    this.form.patchValue({'slugsDestinationDe': this.createSlugFromString(this.googleDataDe.name)});
    this.form.patchValue({'destinationNameEn': this.googleDataEn.name});
    this.form.patchValue({'slugsDestinationEn': this.createSlugFromString(this.googleDataEn.name)});
    this.form.patchValue({'destinationNameEnGb': this.googleDataEnGb.name});
    this.form.patchValue({'slugsDestinationEnGb': this.createSlugFromString(this.googleDataEnGb.name)});
    this.form.patchValue({'destinationNameEs': this.googleDataEs.name});
    this.form.patchValue({'slugsDestinationEs': this.createSlugFromString(this.googleDataEs.name)});
    this.form.patchValue({'destinationNameIt': this.googleDataIt.name});
    this.form.patchValue({'slugsDestinationIt': this.createSlugFromString(this.googleDataIt.name)});
    this.form.patchValue({'destinationNameEnAu': this.googleDataEnAu.name});
    this.form.patchValue({'slugsDestinationEnAu': this.createSlugFromString(this.googleDataEnAu.name)});
    this.form.patchValue({'destinationNamePl': this.googleDataPl.name});
    this.form.patchValue({'slugsDestinationPl': this.createSlugFromString(this.googleDataPl.name)});
  }

  private createSlugFromString(data: string) {
    /* make it lowercase */
    let elem = data.toLowerCase();

    /* replace umlauts */
    elem = elem.replace(/\d/g, '').trim();
    elem = elem.replace(/&/g, 'und');
    elem = elem.replace(/ä/g, 'ae');
    elem = elem.replace(/ü/g, 'ue');
    elem = elem.replace(/ö/g, 'oe');

    /* replace other kinds of umlauts */
    elem = elem.replace(/[áàâãå]/g, 'a');
    elem = elem.replace(/[úùû]/g, 'u');
    elem = elem.replace(/[ç]/g, 'c');
    elem = elem.replace(/[ô]/g, 'o');
    elem = elem.replace(/[ó]/g, 'o');
    elem = elem.replace(/[é]/g, 'e');

    /* replace all non english letters with - */
    elem = elem.replace(/[^A-Za-z0-9\-_]/g, '-');

    /* */
    return elem;
  }

  public async handleGooglePlaceIdChangeOriginal(address: Address) {
    this.destinationGooglePlaceDetails = address;

    await this.getGooglePlaceDetailsFromService();
  }

  public openWebsite() {
    const url = environment.urls.landingpage + '/de/dst/' + this.destination.publicId + '/' + this.destination.slugsDestination.de + '/';
    window.open(url, '_blank');
  }
}
